/* eslint-disable sonarjs/no-duplicate-string */
import { EuroRounded, StarTwoTone } from '@mui/icons-material';
import { TFunction } from '@sortlist-frontend/translation/ssr';
import { formatCurrency } from '@sortlist-frontend/utils';

import { LanguageTopic } from '_core/repos/public-api/topics.repo';

import { AgencyFilters } from './types';

const getFiltersPrivate = (locale: string, languages: LanguageTopic[], t: TFunction): AgencyFilters => ({
  location: {
    id: 'location',
    title: t('longtail:agencyFilters.location.title') ?? '',
    type: 'location-select',
    options: [],
  },
  languages: {
    id: 'languages',
    title: t('longtail:agencyFilters.languages.title') ?? '',
    type: 'multi',
    options: languages.map((language) => ({
      id: language.id,
      label: language.name,
      value: language.id,
    })),
  },
  ratings: {
    id: 'ratings',
    title: t('longtail:agencyFilters.ratings.title') ?? '',
    icon: <StarTwoTone className="text-secondary-500 filter-icon" />,
    type: 'multi',
    options: Array.from({ length: 5 }, (_, i) => ({
      id: (i + 1).toString(),
      label: i === 4 ? `${i + 1}` : `${i + 1}+`,
      value: i === 4 ? [0.99, 1] : [i === 0 ? 0.01 : i * 0.25, 1],
    })),
  },
  // TODO: Uncomment when we trust the data
  // recommended: {
  //   id: 'recommended',
  //   label: (
  //     <div className="layout-column">
  //       <span className="medium text-content-900 small">{t('longtail:agencyFilters.recommended.title') ?? ''}</span>
  //       <span className="small text-secondary-500">{t('longtail:agencyFilters.recommended.description') ?? ''}</span>
  //     </div>
  //   ),
  //   type: 'boolean',
  //   options: [
  //     {
  //       id: '1',
  //       label: t('longtail:agencyFilters.recommended.title') ?? '',
  //       value: [3, 10000],
  //     },
  //   ],
  // },
  // topRecommended: {
  //   id: 'topRecommended',
  //   label: (
  //     <div className="layout-column">
  //       <span className="medium text-content-900 small">
  //         {t('longtail:agencyFilters.topRecommended.title') ?? ''}
  //       </span>
  //       <span className="small text-secondary-500">
  //         {t('longtail:agencyFilters.topRecommended.description') ?? ''}
  //       </span>
  //     </div>
  //   ),
  //   type: 'boolean',
  //   options: [
  //     {
  //       id: '1',
  //       label: t('longtail:agencyFilters.topRecommended.title') ?? '',
  //       value: [5, 10000],
  //     },
  //   ],
  // },
  budgets: {
    id: 'budgets',
    title: t('longtail:agencyFilters.budget.title') ?? '',
    icon: <EuroRounded className="text-secondary-500 filter-icon" />,
    type: 'multi',
    options: [
      [1, 1000],
      [1000, 3000],
      [3000, 5000],
      [5000, 10000],
      [10000, 50000],
      [50000, 100000],
      [100000, 5000000],
    ].map(([min, max], index) => {
      if (min === 1) {
        return {
          id: index.toString(),
          label: `< ${formatCurrency({ value: max, locale, currency: 'EUR' })}`,
          value: [min, max],
        };
      }
      if (max === 5000000) {
        return {
          id: index.toString(),
          label: `${formatCurrency({ value: min, locale, currency: 'EUR' })}+`,
          value: [min, max],
        };
      }
      return {
        id: index.toString(),
        label: `${formatCurrency({ value: min, locale, currency: 'EUR' })} - ${formatCurrency({
          value: max,
          locale,
          currency: 'EUR',
        })}`,
        value: [min, max],
      };
    }),
  },
  worksCounts: {
    id: 'worksCounts',
    title: t('longtail:agencyFilters.works.title') ?? '',
    type: 'multi',
    options: [
      { min: 0, max: 5, type: 'lessThan' },
      { min: 5, max: 25, type: 'between' },
      { min: 25, max: 50, type: 'between' },
      { min: 50, max: 100, type: 'between' },
      { min: 100, max: 100000000, type: 'moreThan' },
    ].map((range, index) => ({
      id: (index + 1).toString(),
      label:
        range.type === 'lessThan'
          ? t('longtail:agencyFilters.works.lessThan', { count: range.max }) ?? ''
          : range.type === 'moreThan'
            ? t('longtail:agencyFilters.works.moreThan', { count: range.min }) ?? ''
            : t('longtail:agencyFilters.works.between', { min: range.min, max: range.max }) ?? '',
      value: [range.min, range.max],
    })),
  },
  teamSizes: {
    id: 'teamSizes',
    title: t('longtail:agencyFilters.teamSize.title') ?? '',
    type: 'multi',
    options: [
      { min: 0, max: 1, type: 'onePerson' },
      { min: 2, max: 10, type: 'between' },
      { min: 10, max: 50, type: 'between' },
      { min: 50, max: 100, type: 'between' },
      { min: 100, max: 500, type: 'between' },
      { min: 500, max: 1000, type: 'between' },
      { min: 1000, max: 5000, type: 'between' },
      { min: 5000, max: 100000, type: 'moreThan' },
    ].map((range, index) => ({
      id: (index + 1).toString(),
      label:
        range.type === 'onePerson'
          ? t('longtail:agencyFilters.teamSize.onePerson') ?? ''
          : range.type === 'moreThan'
            ? t('longtail:agencyFilters.teamSize.moreThan', { count: range.min }) ?? ''
            : t('longtail:agencyFilters.teamSize.between', { min: range.min, max: range.max }) ?? '',
      value: [range.min, range.max],
    })),
  },
  awardsCounts: {
    id: 'awardsCounts',
    title: t('longtail:agencyFilters.awards.title') ?? '',
    label: (
      <div className="layout-column">
        <span className="medium text-content-900 small">{t('longtail:agencyFilters.awards.title') ?? ''}</span>
        <span className="small text-secondary-500">{t('longtail:agencyFilters.awards.description') ?? ''}</span>
      </div>
    ),
    type: 'boolean',
    options: [
      {
        id: '1',
        label: t('longtail:agencyFilters.awards.title') ?? '',
        value: [4, 100000],
      },
    ],
  },
});

export const getFilters = (locale: string, languages: LanguageTopic[], t: TFunction): AgencyFilters => {
  return getFiltersPrivate(locale, languages, t);
};

export const getFiltersForGraphql = (locale: string, languages: LanguageTopic[]): AgencyFilters => {
  return getFiltersPrivate(locale, languages, ((x: string) => x) as any);
};
