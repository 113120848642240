import { cx } from '@emotion/css';
import { LockTwoTone } from '@mui/icons-material';
import type { ChangeEvent, FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';

import type { Color } from '../types';
import * as S from './styles';

export type ToggleProps = {
  color?: Color;
  label?: string | ReactElement;
  locked?: boolean;
  dataTestId?: string;
  icon?: ReactNode;
  toggled: boolean;
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
} & Partial<InputHTMLAttributes<HTMLInputElement>>;

const defaultProps = {
  locked: false,
  toggled: false,
  color: 'primary',
};

export const Toggle: FC<ToggleProps> = (props: ToggleProps) => {
  const { locked, color, toggled, label, dataTestId, onChange, icon, ...restProps } = {
    ...defaultProps,
    ...props,
  };

  return (
    <div className="layout-row layout-align-start-center">
      <div className="relative layout-row">
        <S.Toggled
          {...restProps}
          id={restProps.id}
          data-testid={dataTestId ? dataTestId : 'toggle'}
          type="checkbox"
          color={color as Color}
          locked={locked}
          checked={toggled}
          toggled={toggled}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(e);
            }
          }}
        />
        {locked && !icon && (
          <S.LockIcon
            toggled={toggled}
            data-testid={'toggleLockIcon'}
            className={'absolute layout-column layout-align-center-center'}>
            <LockTwoTone style={{ fontSize: 12 }} className={cx(toggled ? 'text-neutral-100' : 'text-secondary-500')} />
          </S.LockIcon>
        )}
        {icon && (
          <S.Icon
            toggled={toggled}
            data-testid={'toggleCustomIcon'}
            className={'absolute layout-column layout-align-center-center'}>
            {icon}
          </S.Icon>
        )}
      </div>
      {label && (
        <S.Label data-testid={'toggleLabel'} htmlFor={restProps.id} className="px-8">
          {label}
        </S.Label>
      )}
    </div>
  );
};
