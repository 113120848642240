import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import { Fragment, ReactElement, useRef } from 'react';

import { NavigationData } from '_components/layout/Layout';
import { Expertise } from '_core/repos/expertises.repo';
import { Agencies, Agency, Page, PagesAgency } from '_types/public-api';

import { AgencyCard } from './AgencyCard';
import { NoFilterResultsState } from './NoFilterResultsState';

type AgenciesPanelProps = {
  className?: string;
  organicAgencies?: Agencies;
  paidAgencies?: Agencies;
  page: Page;
  t: TFunction;
  divider?: ReactElement;
  isSeaPage?: boolean;
  navigationData: NavigationData;
  agencyCardsLayout?: string;
  appRouter?: boolean;
  expertises?: Expertise[];
};

export const PAID_AGENCIES_SECTION_LIMIT = 6;

const getAgenciesList = (isSeaPage: boolean, agencies: Agency[], paidAgencies: Agency[]) => {
  if (!isSeaPage) {
    return agencies ?? [];
  }

  const paidAgenciesList = paidAgencies ?? [];
  const organicAgenciesList =
    agencies?.filter((agency) => paidAgencies?.find((paidAgency) => paidAgency.id === agency.id) == null) ?? [];

  return [...paidAgenciesList, ...organicAgenciesList].slice(0, 20);
};

const getPagesAgenciesList = (isSeaPage: boolean, agencies: PagesAgency[], paidAgencies: PagesAgency[]) => {
  if (!isSeaPage) {
    return agencies ?? [];
  }

  const paidAgenciesList = paidAgencies ?? [];
  const organicAgenciesList =
    agencies?.filter(
      (agency) =>
        paidAgencies?.find(
          (paidAgency) => paidAgency?.relationships?.agency?.data?.id === agency.relationships?.agency?.data?.id,
        ) == null,
    ) ?? [];

  return [...paidAgenciesList, ...organicAgenciesList].slice(0, 20);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AgenciesPanel = (props: AgenciesPanelProps) => {
  const { isActive: isExperimentDonutActive } = useIsFeatureActive('experience-level-donut');
  const {
    organicAgencies: organicAgenciesProp,
    paidAgencies: paidAgenciesProp,
    page,
    t,
    divider,
    isSeaPage,
    navigationData,
    appRouter = false,
    agencyCardsLayout,
    expertises,
  } = props;

  const { noFiltersResults } = navigationData;

  const defaultHorizontalDisplay =
    isExperimentDonutActive || page?.data?.attributes?.page_options?.cards?.layout === 'horizontal';
  const horizontalDisplay = agencyCardsLayout === 'horizontal';
  const fullWidthLayout = defaultHorizontalDisplay || horizontalDisplay;
  const ref = useRef<HTMLDivElement>(null);

  const {
    data: agencies,
    included,
    meta: { pagination },
  } = organicAgenciesProp || { meta: {} };

  const { data: paidAgencies, included: inclduedPaid } = paidAgenciesProp || { meta: {} };

  const includedAgencies =
    included &&
    included.map((obj) => {
      if (obj.attributes.team_size === null) return { ...obj, attributes: { ...obj.attributes, team_size: 0 } };
      return obj;
    });

  const includedPaidAgencies =
    inclduedPaid &&
    inclduedPaid.map((obj) => {
      if (obj.attributes.team_size === null) return { ...obj, attributes: { ...obj.attributes, team_size: 0 } };
      return obj;
    });

  const hasPaidAgencies = paidAgencies && paidAgencies.length > 0;

  const agenciesList = getAgenciesList(!!isSeaPage, includedAgencies ?? [], inclduedPaid ?? []);
  const pagesAgenciesList = getPagesAgenciesList(!!isSeaPage, agencies ?? [], paidAgencies ?? []);

  useIsFeatureActive('cards-translated-description', {
    triggerEvent: true,
    viewEventRef: ref,
  });

  useIsFeatureActive('cards-expertise-description', {
    triggerEvent: true,
    viewEventRef: ref,
  });

  return (
    <Fragment>
      {agenciesList != null && agenciesList.length === 0 && Boolean(noFiltersResults) ? <NoFilterResultsState /> : null}

      {!isSeaPage && hasPaidAgencies ? (
        <div
          className={`container-lg pt-24 ${defaultHorizontalDisplay ? 'agencies-horizontal-layout' : 'agencies-card-layout'}`}>
          <div className={'pb-16'}>
            <h2 className="medium p">{page?.data.attributes.title.featured_title}</h2>
          </div>
          <ul className="m-0 p-0 gap-x-16 gap-y-16 grid-list">
            {includedPaidAgencies
              ?.slice(0, PAID_AGENCIES_SECTION_LIMIT)
              .map((agency, key) => (
                <AgencyCard
                  appRouter={appRouter}
                  data={paidAgencies[key]}
                  agency={agency}
                  key={agency.id}
                  keyInArray={key}
                  page={page}
                  t={t}
                  navigationData={navigationData}
                  paid
                  expertises={expertises}
                />
              ))}
          </ul>
        </div>
      ) : null}
      {agencies == null || (agenciesList != null && agenciesList.length === 0 && Boolean(noFiltersResults)) ? null : (
        <div
          ref={ref}
          id="content"
          className={`container-lg relative pt-24 ${fullWidthLayout ? 'agencies-horizontal-layout' : 'agencies-card-layout'}`}>
          {divider}
          <ul className="m-0 p-0 gap-x-16 gap-y-16 grid-list">
            {agenciesList.map((agency, key) => (
              <AgencyCard
                appRouter={appRouter}
                data={
                  pagesAgenciesList.find(
                    (pageAgency) => pageAgency?.relationships?.agency?.data?.id === agency.id,
                  ) as PagesAgency
                }
                agency={agency}
                key={agency.id}
                keyInArray={key}
                page={page}
                t={t}
                navigationData={navigationData}
                paid={isSeaPage && paidAgencies != null && key < paidAgencies?.length}
                expertises={expertises}
              />
            ))}
          </ul>
        </div>
      )}
    </Fragment>
  );
};
