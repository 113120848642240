import { cx } from '@emotion/css';
import { CloseRounded, LocationOnTwoTone } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import { Button, Divider, Tag, Toggle } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { NavigationData } from '_components/layout/Layout';
import { SearchTopics } from '_components/SearchTopic/SearchTopics';
import { useLanguagesTopics } from '_core/repos/public-api/topics.repo';

import { getFilters } from './constants';
import { FilterTag } from './FilterTag';
import { AgencyFilterKey, Filters, LocationFilter, Option } from './types';
import { toggleFilterOption } from './utils';

type Props = {
  navigationData: NavigationData;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  setOpen: (open: boolean) => void;
  serviceTopicGraphqlId?: string;
};
const MIN_DISPLAY_FILTERS_COUNT = 10;

export const FiltersDrawer = (props: Props) => {
  const { navigationData, filters, setFilters, setOpen, serviceTopicGraphqlId } = props;
  const { t } = useTranslation();
  const { locale, origin } = navigationData;
  const { data: languages } = useLanguagesTopics(locale, origin);
  const [expandedFilters, setExpandedFilters] = useState<{ [key: string]: boolean }>({});

  const filtersOptions = useMemo(() => getFilters(locale, languages ?? [], t), [locale, languages, t]);
  const [filtersCount, setFiltersCount] = useState(Object.keys(filters).length);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const { control, handleSubmit, reset, setValue } = useForm<Filters>({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  // Initialize form with query params
  useEffect(() => {
    const count = Object.entries(filters).reduce((acc, [key, value]) => {
      if (key in filters) {
        if (Array.isArray(value)) {
          return acc + value.length;
        }
        return acc + 1;
      }
      return acc;
    }, 0);
    setFiltersCount(count);
    reset(filters);
  }, [filters, filtersOptions]);

  const onSubmit = (data: Filters) => {
    // Remove empty values and format data
    const cleanedData = Object.fromEntries(
      Object.entries(data).filter(([, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value != null;
      }),
    );

    setFilters(cleanedData);
    setOpen(false);
  };

  const handleReset = () => {
    setFilters({});
    setOpen(false);
  };

  const formValues = useWatch({ control });

  useEffect(() => {
    const count = Object.entries(formValues).reduce((acc, [, value]) => {
      if (value == null) return acc;

      if (Array.isArray(value)) {
        return acc + value.length;
      }
      return acc + 1;
    }, 0);
    if (isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }
    setFiltersCount(count);
  }, [formValues]);

  return (
    <Drawer open={true} onClose={() => setOpen(false)}>
      <form className="layout-column height-100" onSubmit={handleSubmit(onSubmit)}>
        <div className="layout-column flex-grow height-100 drawer-filters">
          <div className="p-16 layout-row layout-align-space-between-center">
            <span className="medium serif">{t('longtail:agencyFilters.title')}</span>
            <Button
              icon={<CloseRounded />}
              size="xxs"
              buttonStyle="secondary"
              buttonVariant="light"
              fab
              onClick={() => setOpen(false)}
            />
          </div>

          <Divider />

          <div className="layout-column layout-align-start-center p-16 flex-grow overflow-auto">
            <div className="layout-column layout-align-start-center gap-12 width-100">
              <p className="text-left width-100 serif medium small">{t('longtail:agencyFilters.location.title')}</p>
              <Controller
                control={control}
                name="location"
                render={({ field: { onChange, value, ref } }) => (
                  <div className="layout-column layout-align-start-start gap-12 width-100">
                    <SearchTopics
                      ref={ref}
                      page="directory"
                      cta="filters"
                      element="location"
                      placeholder={t('longtail:agencyFilters.location.searchLocation')}
                      prependComponent={
                        <div className="bg-secondary-100 rounded-top-left-sm rounded-bottom-left-sm border-secondary-300 p-8 layout-column layout-align-center-center flex">
                          <LocationOnTwoTone className="text-secondary-500 filter-icon" />
                        </div>
                      }
                      extraTopicId={serviceTopicGraphqlId}
                      navigationData={navigationData}
                      type="location"
                      onChange={(option) => {
                        onChange({
                          id: (option as Option).value,
                          label: (option as Option).label,
                        } as LocationFilter & string[]);
                      }}
                    />
                    {value != null ? (
                      <FilterTag
                        id={value.id}
                        label={value.label}
                        filterKey="location"
                        isSelected={value != null}
                        onToggle={() => {
                          onChange(null);
                        }}
                        showCloseButton
                      />
                    ) : null}
                  </div>
                )}
              />
            </div>

            <div className="layout-column layout-align-start-center width-100 text-left">
              {Object.entries(filtersOptions)
                .filter(([key]) => key !== 'location')
                .map(([key, filter]) => (
                  <Controller
                    control={control}
                    key={key}
                    name={key as AgencyFilterKey}
                    render={({ field: { onChange, value, ref } }) => {
                      const showExpandButton = filter.options.length > MIN_DISPLAY_FILTERS_COUNT;
                      const isExpanded = expandedFilters[key];
                      const visibleOptions = isExpanded
                        ? filter.options
                        : filter.options.slice(0, MIN_DISPLAY_FILTERS_COUNT);

                      return (
                        <div
                          ref={ref}
                          key={key}
                          className={cx(
                            'layout-column layout-align-start-center gap-12 width-100',
                            filter.title != null ? 'mt-32' : 'mt-16',
                          )}>
                          {filter.title != null ? (
                            <p className="text-left width-100 serif medium small">
                              {filter.title} {`(${value?.length ?? 0})`}
                            </p>
                          ) : null}
                          <div className="layout-row layout-align-start-center gap-8 width-100 layout-wrap">
                            {filter.type === 'boolean' ? (
                              <Toggle
                                label={filter.label}
                                toggled={value?.length != null && value?.length > 0}
                                onChange={(e) =>
                                  setValue(key as AgencyFilterKey, e.target.checked ? [filter.options[0].id] : [])
                                }
                              />
                            ) : (
                              <Fragment>
                                {visibleOptions.map((option) => (
                                  <FilterTag
                                    key={option.id}
                                    id={option.id}
                                    label={option.label}
                                    filterKey={key}
                                    isSelected={value != null && (!Array.isArray(value) || value?.includes(option.id))}
                                    onToggle={() => {
                                      if (value == null) return onChange([option.id]);
                                      const newValue = toggleFilterOption(
                                        formValues as Filters,
                                        key as AgencyFilterKey,
                                        option.id,
                                      );
                                      onChange(newValue);
                                    }}
                                  />
                                ))}
                                {showExpandButton && !isExpanded && (
                                  <button
                                    onClick={() => setExpandedFilters((prev) => ({ ...prev, [key]: true }))}
                                    style={{ backgroundColor: 'transparent', border: 'none', padding: 0 }}>
                                    <Tag
                                      label={
                                        <div className="layout-row layout-align-center-center">
                                          + {filter.options.length - 10}
                                        </div>
                                      }
                                      size="xs"
                                      color="secondary"
                                      type="rounded"
                                      variant="light"
                                    />
                                  </button>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  />
                ))}
            </div>
          </div>

          <Divider />

          <div className="p-16 layout-row layout-align-end">
            {filtersCount > 0 && (
              <Button
                label={t('longtail:agencyFilters.reset')}
                buttonStyle="secondary"
                buttonVariant="default"
                size="md"
                onClick={handleReset}
              />
            )}
            <Button
              label={`${t('longtail:agencyFilters.applyFilters')} (${filtersCount})`}
              buttonStyle="primary"
              buttonVariant="raised"
              size="md"
              type="submit"
            />
          </div>
        </div>
      </form>
    </Drawer>
  );
};
