import { cx } from '@emotion/css';
import { useMediaQuery } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC, Fragment } from 'react';

import { GreenStars, Logo } from './styles';

const trustpilotProfileLink = 'https://www.trustpilot.com/review/www.sortlist.com';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

type TrustpilotWidgetProps = {
  reviewsCount?: number;
  reviewsAverage?: number | string;
  className?: string;
  hasTitle?: boolean;
};

const defaultProps = {
  reviewsCount: 0,
  reviewsAverage: 0,
  hasTitle: false,
};

export const TrustpilotWidget: FC<TrustpilotWidgetProps> = (props) => {
  const { reviewsCount, reviewsAverage, className, hasTitle } = { ...defaultProps, ...props };
  const { media } = useMediaQuery();
  const { t } = useTranslation(['common', 'agency']);

  const average = typeof reviewsAverage === 'string' ? Number(reviewsAverage) : reviewsAverage;

  return (
    <a
      className={cx(className, 'rounded-md shadow-2 bg-secondary-900 p-8 px-32 py-16 display-inline-block text-center')}
      href={trustpilotProfileLink}
      target="_blank"
      rel="nofollow noopener">
      {hasTitle && media.gtXs ? (
        <span className="text-secondary-100 pb-12 display-block">
          <Trans i18nKey={'common:trustpilotWidget.title'} count={Number((average * 5).toFixed(1))}>
            Sortlist is rated
            <b>
              {{ count: Number((average * 5).toFixed(1)) }}/5
            </b>
            by businesses across the world.
          </Trans>
        </span>
      ) : null}
      {hasTitle && !media.gtXs ? (
        <div className="mb-20 layout-column text-center">
          <span className="text-secondary-100 h4 mb-8">
            <Trans i18nKey={'common:trustpilotWidget.titleExcellent'}>
              Sortlist is rated
              <br />
              <b>"Excellent"</b>
            </Trans>
          </span>
          <span className="text-secondary-100">
            <Trans i18nKey={'common:trustpilotWidget.rating'} count={Number((average * 5).toFixed(1))}>
              <b className="p">{{ count: Number((average * 5).toFixed(1)) }}</b>
              <span className="small"> out of 5</span>
            </Trans>
          </span>
        </div>
      ) : null}
      <div className="layout-gt-xs-row layout-column layout-align-center-center">
        {!hasTitle ||
          (media.gtXs && <span className={'text-secondary-100 m-8'}>{t('common:trustpilotWidget.grade')}</span>)}
        <GreenStars rate={average ? average : 0} count={1} className="m-8" size={20} />
        <span className={'text-secondary-100 m-8'}>
          {reviewsCount ? (
            <Trans i18nKey={'common:trustpilotWidget.reviewsCount'} count={reviewsCount}>
              Based on
              <b className="underline">
                {{ count: reviewsCount }} reviews
              </b>
            </Trans>
          ) : null}
        </span>
        <Logo
          className="mx-8"
          src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/trustpilot.svg`)}
          alt="Trustpilot"
        />
      </div>
    </a>
  );
};
