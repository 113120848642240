'use client';

import { CalendarViewDayTwoTone, GridOnTwoTone } from '@mui/icons-material';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

import { useTracker } from '_core/hooks/use-tracker';

import { AGENCY_CARDS_LAYOUT, AGENCY_CARDS_LAYOUT_FULL, AGENCY_CARDS_LAYOUT_NORMAL, LayoutType } from './constants';

type Props = {
  agencyCardsLayoutValue: string;
};

export const LayoutSwitch = (props: Props) => {
  const { agencyCardsLayoutValue } = props;
  const [agencyCardsLayout, setAgencyCardsLayout] = useState<LayoutType>(agencyCardsLayoutValue as LayoutType);
  const isFullWidth = agencyCardsLayout === AGENCY_CARDS_LAYOUT_FULL;
  const { trackUntyped } = useTracker();

  const { isActive: isExperienceDonutActive } = useIsFeatureActive('experience-level-donut');
  const { variant: projectHighlightVariant } = useIsFeatureActive('positive-review');
  const { variant: reduceInfoVariant } = useIsFeatureActive('reduce-display-information');

  const hasRunningExperiment = isExperienceDonutActive || projectHighlightVariant != null || reduceInfoVariant != null;

  const trackLayoutSwitch = (layout: 'grid' | 'list') => {
    trackUntyped('layoutSwitched', {
      page: 'longtail',
      layout: layout,
    });
  };

  const changeLayout = (layout: LayoutType) => {
    deleteCookie(AGENCY_CARDS_LAYOUT);
    setCookie(
      AGENCY_CARDS_LAYOUT,
      layout === AGENCY_CARDS_LAYOUT_FULL ? AGENCY_CARDS_LAYOUT_FULL : AGENCY_CARDS_LAYOUT_NORMAL,
    );
    trackLayoutSwitch(layout === AGENCY_CARDS_LAYOUT_FULL ? 'list' : 'grid');

    const element = document.getElementById('content');
    if (element != null) {
      element.classList.remove('agencies-horizontal-layout');
      element.classList.remove('agencies-card-layout');
      element.classList.add(
        layout === AGENCY_CARDS_LAYOUT_FULL ? 'agencies-horizontal-layout' : 'agencies-card-layout',
      );
    }
  };

  useEffect(() => {
    changeLayout(agencyCardsLayout);
  }, [agencyCardsLayout]);

  useEffect(() => {
    const layout = getCookie(AGENCY_CARDS_LAYOUT) ?? agencyCardsLayoutValue;
    setAgencyCardsLayout(hasRunningExperiment ? 'horizontal' : (layout as LayoutType));
  }, []);

  return (
    <div className="layout-row layout-align-center-center hide show-gt-sm">
      <GridOnTwoTone
        style={{ fontSize: 16 }}
        className={`mr-12 cursor-pointer ${isFullWidth ? 'text-secondary-500' : 'text-primary-500'}`}
        onClick={() => {
          setAgencyCardsLayout(AGENCY_CARDS_LAYOUT_NORMAL);
        }}
      />
      <CalendarViewDayTwoTone
        style={{ fontSize: 16 }}
        className={`cursor-pointer ${isFullWidth ? 'text-primary-500' : 'text-secondary-500'}`}
        onClick={() => {
          setAgencyCardsLayout(AGENCY_CARDS_LAYOUT_FULL);
        }}
      />
    </div>
  );
};
