import styled from '@emotion/styled';

import type { ToggleProps } from '.';
import { COLORS } from '../../variables';
import type { Color } from '../types';

const getShade = (color?: Color) => {
  switch (color) {
    case 'secondary':
      return COLORS.SECONDARY_300;
    case 'success':
      return COLORS.SUCCESS_500;
    case 'warning':
      return COLORS.WARNING_500;
    case 'danger':
      return COLORS.DANGER_500;
    case 'agency':
      return COLORS.AGENCY_500;
    case 'client':
      return COLORS.CLIENT_500;
    default:
      return COLORS.PRIMARY_500;
  }
};

export const Icon = styled.div`
  ${(props: ToggleProps) => `
    right: ${props.toggled ? 'initial' : '4px'};
    left: ${props.toggled ? '4px' : 'initial'};
    top: 4px;
    pointer-events: none;
  `}
`;

export const LockIcon = styled.div`
  ${(props: ToggleProps) => `
    right: ${props.toggled ? 'initial' : '4px'};
    left: ${props.toggled ? '4px' : 'initial'};
    top: 4px;
    opacity: 0.6;
    pointer-events: none;
  `}
`;

export const Toggled = styled.input`
  ${(props: ToggleProps) => `
    &[type=checkbox] {
      -webkit-appearance: none;
      appearance: none;
      outline: none;
      border: none;
      height: 1.25rem;
      width: 2.375rem;
      padding: 0;
      background: red;
      border-radius: 0.625rem;
      transition: all 0.1s ease-in-out;
      position: relative;
      background-color: ${COLORS.SECONDARY_200};
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        transition: all 0.1s ease-in-out;
        background-color: ${COLORS.NEUTRAL_100};
        transform: translate('0px, 0px');
      }
    }
    &[type=checkbox]:checked {
      background-color: ${getShade(props.color)};
      &:after {
        transform: translate(calc(100% + 2px), 0);
      }
    }
    &[type=checkbox]:disabled {
      cursor: not-allowed;
      background-color: ${props.toggled ? getShade(props.color) : COLORS.SECONDARY_200};
      &:after {
        ${props.toggled ? 'transform: translate(calc(100% + 2px), 0);' : 'transform: translate(0, 0);'};
      }
    }
  `}
`;

export const Label = styled.label`
  line-height: 1.25rem;
`;
