import { cx } from '@emotion/css';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC, Fragment } from 'react';

import { PublicApiRepo, useGetLinks } from '_core/repos/public-api.repo';
import { customConfig } from '_features/custom/custom.config';
import { filteredLinks, type LinkDetails } from '_features/longtail/utils';

import * as S from './styles';

type Props = {
  title: string;
  links: LinkDetails[];
  className?: string;
};

const AgenciesList: FC<Props> = (props) => {
  const { title, links, className } = props;
  return (
    <S.AgenciesListWrapper className={cx(className, 'pb-64 flex-50 text-left')}>
      <p className={'bold text-left pb-16 small'}>{title}</p>
      <Fragment>
        <div className="layout-row layout-wrap layout-align-xs-start-center">
          {links
            .sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
            .map((link, key) => {
              return (
                //@TODO: fix scroll on top
                //<Link href={link.url} passHref key={key}>
                //</Link>
                <S.Anchor
                  key={key}
                  href={link.url}
                  className="small text-wrap cursor-pointer rounded-xl p-8 bg-primary-100 text-primary-500">
                  {link.anchor}
                </S.Anchor>
              );
            })}
        </div>
      </Fragment>
    </S.AgenciesListWrapper>
  );
};

export const OtherAgencies = () => {
  const { t } = useTranslation(customConfig.i18nNamespaces);
  const { data: ssrData } = useGetLinks(PublicApiRepo.publicApiUrl);

  const otherServices = filteredLinks(ssrData, 'other_expertise');
  const sameArea = filteredLinks(ssrData, 'other_near_location');
  const childArea = filteredLinks(ssrData, 'other_child_location');

  const otherAgencies = !!otherServices?.length || !!sameArea?.length || !!childArea?.length;
  if (!otherAgencies) return <Fragment></Fragment>;

  return (
    <div className="text-center pt-64">
      <h2 className="bold pb-8 serif h2">{t('longtail:otherAgencies.title')}</h2>
      <p className="pb-40">{t('longtail:otherAgencies.subtitle')}</p>

      <div className="layout-column layout-gt-sm-row layout-align-space-between layout-align-gt-sm-space-between-start pb-24">
        {!!otherServices?.length && (
          <AgenciesList title={t('longtail:otherAgencies.otherServices')} links={otherServices} />
        )}
        {!!sameArea?.length && <AgenciesList title={t('longtail:otherAgencies.sameArea')} links={sameArea} />}
        {!!childArea?.length && <AgenciesList title={t('longtail:otherAgencies.childArea')} links={childArea} />}
      </div>
    </div>
  );
};
