import { cx } from '@emotion/css';
import { FC } from 'react';

import { getValidHTML } from '_core/utils/utils.ssr';
import { Page } from '_types/public-api';

type DescriptionProps = {
  className?: string;
  description: Page['data']['attributes']['description'];
};

export const Description: FC<DescriptionProps> = (props) => {
  const { className, description } = props;

  return (
    <section className={cx(className)} dangerouslySetInnerHTML={{ __html: getValidHTML(description.html) as string }} />
  );
};
