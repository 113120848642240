import { Monitor } from '@sortlist-frontend/mlm';
import { isNonEmptyArray } from '@sortlist-frontend/utils';

import { AgenciesFilters, GraphqlAgencyFilterKey } from '_core/repos/public-api/agencies.repo';
import { Domain } from '_types/public-api';

import { AgencyFilterKey, AgencyFilters, Filters, Option, SelectedFilters } from './types';

export const parseQueryValue = (queryValue: string | string[] | undefined): Filters => {
  if (queryValue == null || Array.isArray(queryValue)) return {};

  return JSON.parse(queryValue);
};

export const findOptionById = (filters: AgencyFilters, filterKey: AgencyFilterKey, optionId: string) => {
  return filters[filterKey]?.options.find((opt) => opt.id === optionId) ?? { id: optionId, label: '', value: null };
};

export const getSelectedOptions = (
  filters: AgencyFilters,
  filterKey: AgencyFilterKey,
  queryFilters: Filters,
): Option[] => {
  const selectedIds = queryFilters[filterKey] ?? [];
  return (
    filters[filterKey]?.options
      .filter((option) => selectedIds?.includes(option.id))
      .map((option) => ({
        label: option.label,
        value: option.id,
      })) ?? []
  );
};

export const getUnselectedOptions = (
  filters: AgencyFilters,
  filterKey: AgencyFilterKey,
  queryFilters: Filters,
): Option[] => {
  const selectedIds = queryFilters[filterKey] ?? [];

  return (
    filters[filterKey]?.options
      .filter((option) => !selectedIds?.includes(option.id))
      .map((option) => ({
        label: option.label,
        value: option.id,
      })) ?? []
  );
};

export const toggleFilterOption = (
  queryFilters: Filters,
  filterKey: AgencyFilterKey,
  optionId: string,
): string[] | null => {
  if (filterKey === 'location') return null;
  const currentIds = queryFilters[filterKey] ?? [];

  const newValue = currentIds.includes(optionId)
    ? currentIds.filter((id) => id !== optionId)
    : [...currentIds, optionId];
  return newValue.length > 0 ? newValue : null;
};

export const getActiveFilters = (filters: AgencyFilters, queryFilters: Filters): SelectedFilters => {
  const activeFilters: SelectedFilters = [];
  const locationFilter = queryFilters['location'];

  // Handle other filters
  Object.keys(filters).forEach((filterKey) => {
    if (filterKey === 'location' && locationFilter != null) {
      activeFilters.push({
        key: 'location',
        id: locationFilter.id,
        label: locationFilter.label,
      });
      return;
    }
    const selectedOptions = getSelectedOptions(filters, filterKey as AgencyFilterKey, queryFilters);

    selectedOptions.forEach((option) => {
      activeFilters.push({
        key: filterKey as AgencyFilterKey,
        id: option.value,
        label: option.label,
      });
    });
  });

  return activeFilters;
};

export const filtersToGraphqlFilters = (
  filters: AgencyFilters,
  queryFilters: Filters,
  serviceTopicId: string,
  domain: Domain,
  locale: string,
) => {
  const languagesSelectedFilters: SelectedFilters = [];
  const languagesFilter = queryFilters['languages'];

  const domainLocationId = domain?.location?.id;
  const localeId = domain?.availableLocales?.find((domainLocale) => domainLocale.code === locale)?.id;

  if (languagesFilter != null) {
    // we don't need the languages name on the backend, so we can add only the ids
    // on the frontend this should be avoided
    languagesFilter.forEach((language) => {
      languagesSelectedFilters.push({
        key: 'languages',
        id: language,
        label: language,
      });
    });
  }
  const gqlFilters = languagesSelectedFilters.concat(getActiveFilters(filters, queryFilters)).reduce((acc, filter) => {
    if (filter.key === 'location') {
      if (queryFilters?.location?.id != null) {
        if (isNonEmptyArray(acc.topics)) {
          acc.topics = [...acc.topics, queryFilters.location.id];
        } else {
          acc.topics = [queryFilters.location.id];
        }
      }
      return acc;
    }

    if (filter.key === 'languages') {
      if (isNonEmptyArray(acc.topics) && queryFilters.languages != null) {
        acc.topics = [...acc.topics, ...queryFilters.languages];
      } else {
        acc.topics = queryFilters.languages;
      }
      return acc;
    }

    const { value } = findOptionById(filters, filter.key, filter.id);

    if (value == null) {
      Monitor.captureException(`Filter ${filter.key} with id ${filter.id} not found`);
      return acc;
    }

    if (typeof value === 'string') {
      // should never happen or it should be handled
      Monitor.captureException(`Filter ${filter.key} with id ${filter.id} is not an array`);
      return acc;
    }

    const filterKey: GraphqlAgencyFilterKey = filter.key;
    // override the behaviour
    // TODO: Uncomment when we trust the data
    // if (filter.key === 'recommended' || filter.key === 'topRecommended') {
    //   filterKey = 'reviewsCounts';
    // } else {
    // }
    // filterKey =

    if (acc[filterKey] != null) {
      acc[filterKey]?.push(value);
    } else {
      acc[filterKey] = [value];
    }
    return acc;
  }, {} as AgenciesFilters); //simplify namings of types please...

  const defaultPageTopicIds = [serviceTopicId, domainLocationId, localeId as string];

  const topics = [...new Set([...(gqlFilters.topics ?? []), ...defaultPageTopicIds])];
  return { ...gqlFilters, topics };
};
