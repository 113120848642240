import { useConfig } from '@sortlist-frontend/config';
import { Button } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import Image from 'next/image';

import { OPEN_FILTERS_DRAWER, RESET_FILTERS, useFiltersMessage } from '../Filters/FiltersBar';

const s3BucketUrl = useConfig().publicEnv.s3Bucket;
const noFilterResultsImage = ImageSourceHelper.fromUrl(
  `${s3BucketUrl}/illustrations/longtails/filters-empty-state-2.webp`,
);

export const NoFilterResultsState = () => {
  const { t } = useTranslation();

  const { sendMessage } = useFiltersMessage();

  return (
    <div className="layout-column layout-align-center-center gap-y-16" style={{ padding: '192px 0' }}>
      <Image src={noFilterResultsImage} alt={t('longtail:agencyFilters.noResults.title')} width={400} height={232} />
      <div className="layout-column layout-align-center-center">
        <h2 className="medium p">{t('longtail:agencyFilters.noResults.title')}</h2>
        <p className="regular p">{t('longtail:agencyFilters.noResults.subtitle')}</p>
      </div>
      <div className="layout-row layout-align-center-center gap-x-8">
        <Button
          buttonStyle="secondary"
          buttonVariant="outlined"
          size="xxs"
          onClick={() => {
            sendMessage(RESET_FILTERS);
          }}>
          {t('longtail:agencyFilters.reset')}
        </Button>
        <Button
          buttonStyle="secondary"
          buttonVariant="light"
          size="xxs"
          onClick={() => {
            sendMessage(OPEN_FILTERS_DRAWER);
          }}>
          {t('longtail:agencyFilters.editFilters')}
        </Button>
      </div>
    </div>
  );
};
