import { TFunction } from '@sortlist-frontend/translation/ssr';

import { LinkDetails } from '_features/longtail/utils';

import { Breadcrumbs } from '../../components/Breadcrumbs';

const firstPositionBreadcrumbList = (homePageUrl: string, t: TFunction) => {
  return [
    {
      position: 0,
      name: t('common:general.home'),
      item: homePageUrl,
    },
  ];
};
const breadcrumbItems = (urls: LinkDetails[]) => {
  if (urls.length === 0) return [];
  return urls.map(({ url, anchor }, index) => {
    return {
      position: index + 1,
      name: anchor,
      item: url,
    };
  });
};

export const getBreadcrumbList = (urls: LinkDetails[], homePageUrl: string, t: TFunction) => {
  return [...firstPositionBreadcrumbList(homePageUrl, t), ...breadcrumbItems(urls)];
};
