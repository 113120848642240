import styled from '@emotion/styled';

export const WorkCardThumbnail = styled.div`
  height: 14rem;
  min-height: 14rem;
  min-width: 100%;

  > img {
    object-fit: cover;
  }
`;
