import { TFunction } from '@sortlist-frontend/translation/server-only';

import { ExpandablePanels, QuestionAndAnswer } from '_components/common/ExpandablePanels';
import { Page } from '_types/public-api';

type Props = {
  page: Page;
  t: TFunction;
};

export const Faq = (props: Props) => {
  const { page, t } = props;

  return (
    <div className="py-64">
      <h2 className="bold text-center pb-32 h3">{t('longtail:faq.title')}</h2>

      <ExpandablePanels
        QAList={page.data.attributes.faq?.items as unknown as QuestionAndAnswer[]}
        className="rounded-lg border shadow-2"
        html
      />
    </div>
  );
};
