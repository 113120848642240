import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import Image from 'next/image';
import { FC, useState } from 'react';

import { NoSsr } from '_components/common/NoSsr';
import { WorkDetailModal } from '_components/common/WorkDetailModal';
import { useTracker } from '_core/hooks/use-tracker';
import { onImageError } from '_core/media/get-media.utils';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';
import { customConfig } from '_features/custom/custom.config';
import { Agency, WorkIncludedAttributes } from '_types/public-api';

import * as S from './styles';

type WorkCardWithModalProps = {
  work: WorkIncludedAttributes;
  agency: Agency;
  works: WorkIncludedAttributes[];
  currentIndex: number;
  disableCache: boolean;
};

const { absoluteEntryUrl, absoluteEntryDate, sessionEntryUrl } = getSourceObject();
const missingAgencyLogo = ImageSourceHelper.fromPath('/_img/default/default-image-square.svg');

const WorkCardWithModal: React.FC<WorkCardWithModalProps> = (props) => {
  const { work, works, currentIndex, agency, disableCache } = props;
  const { trackUntyped } = useTracker();
  const [modalOpened, setModalOpened] = useState(false);
  const workSlugs = works.map((work) => work.attributes.slug);
  /**
   * TODO -> retrieve this data from the new public api?
   * THIS is to mimic what we have in the:
   * WorkDetailModal component which is used in agency profile */
  const transformedWorksForModal = works.map((work) => {
    return {
      slug: work.attributes.slug,
      agency_slug: agency.attributes.slug,
      agency_id: agency.attributes.external_id as unknown as number,
      name: work.attributes.name,
      description: work.attributes.description,
      attachment: {
        url: work.attributes.illustration_url || '',
        type: null,
      },
      /* TODO . what we do with this data ? */
      // expertises: null,
      market_type: null,
      market_size: null,
      position: null,
      budget: null,
      company_id: null,
      company_name: null,
      created_at: '',
      sector_name: null,
      start_date: null,
      end_date: null,
    };
  });

  const onClick = () => {
    trackUntyped('workInspected', {
      agencyName: agency.attributes.name,
      agencyPlan: agency.attributes.visibility_plan,
      agencySlug: agency.attributes.slug,
      item: work.attributes.name,
      work_slug: work.attributes.slug,
      absoluteEntryUrl,
      absoluteEntryDate,
      sessionEntryUrl,
    });
    trackUntyped('workImpression', {
      workSlug: work.attributes.slug,
      agencySlug: agency.attributes.slug,
      type: 'longtail-modal',
      absoluteEntryUrl,
      absoluteEntryDate,
      sessionEntryUrl,
    });
    setModalOpened(true);
  };

  const closeDialog = () => {
    setModalOpened(false);
  };

  return (
    // TODO: Remove this and use the component used on the profile. This is unecessary code duplication leading to problems (layout issues for instance) that we don't see right away and need to fix afterwards.
    <div className="p-8 flex-100 flex-sm-50 flex-gt-sm-33">
      <div className="layout-column pb-8">
        <S.WorkCardThumbnail className="relative bg-secondary-100">
          <Image
            fill
            src={ImageSourceHelper.fromUrl(
              work.attributes.illustration_url || '/_img/default/default-image-landscape.svg',
              {
                width: 960,
              },
            )}
            loading={'lazy'}
            alt={work.attributes.name}
            onError={onImageError}
            className="cursor-pointer rounded-md shadow-2"
            onClick={onClick}
            role="presentation"
          />
        </S.WorkCardThumbnail>
        <p className="strong py-16 pl-8 mb-4">{work.attributes.name}</p>
      </div>
      {modalOpened ? (
        <WorkDetailModal
          agencyName={agency.attributes.name}
          agencySlug={agency.attributes.slug}
          agencyBrandingPlan={''} // @Todo: update when data available in the api: https://github.com/sortlist/public-api/issues/357
          agencyLogoUrl={agency.attributes.logo ?? missingAgencyLogo}
          open={modalOpened}
          onClose={closeDialog}
          workSlug={work.attributes.slug}
          workSlugs={workSlugs}
          works={transformedWorksForModal}
          longtailsPage={true} // @TODO -> this should be removed/refactored
          index={currentIndex}
          disableCache={disableCache}
        />
      ) : null}
    </div>
  );
};

type WorkProps = {
  works: WorkIncludedAttributes[];
  agencies: Agency[];
};

export const Works: FC<WorkProps> = (props) => {
  const { works, agencies } = props;
  const { t } = useTranslation(customConfig.i18nNamespaces);
  return (
    <NoSsr>
      <div className="pt-64">
        <h2 className="bold text-center pb-8 serif">{t('longtail:works.title')}</h2>
        <p className="text-center pb-40">{t('longtail:works.subtitle')}</p>

        <div className="layout-row layout-wrap gap-x-16">
          {works?.map((work, index) => {
            const relatedAgency = agencies?.filter((agency) => agency.id === work.relationships.agency.data.id)[0];
            return (
              <WorkCardWithModal
                key={index}
                work={work}
                works={works}
                agency={relatedAgency}
                currentIndex={index}
                disableCache={false}
              />
            );
          })}
        </div>
      </div>
    </NoSsr>
  );
};
