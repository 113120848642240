'use client';

import { cx } from '@emotion/css';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import { Button, Divider } from '@sortlist-frontend/design-system/ssr';
import { FC, Fragment, ReactNode, useState } from 'react';

import { ToggleButton } from '../ToggleButton';

export type QuestionAndAnswer = {
  id: number;
  question: ReactNode;
  answer: ReactNode;
};

const isQAList = (value: unknown): value is QuestionAndAnswer[] => {
  if (value == null) return false;
  if (!Array.isArray(value)) return false;
  if (value.length === 0) return true;

  return 'question' in value[0] && 'answer' in value[0];
};

type ExpandablePanelsProps = {
  header?: {
    icon?: React.ReactElement;
    title: React.ReactElement | string;
  };
  QAList: QuestionAndAnswer[];
  className?: string;
  limit?: number;
  html?: boolean;
};

export const ExpandablePanels: FC<ExpandablePanelsProps> = (props) => {
  const { QAList, className, limit, html, header } = props;
  const [toggledIndex, setToggledIndex] = useState(-1);
  const [allItemsVisible, setAllItemsVisible] = useState(false);

  const updatedLimit = limit ? (allItemsVisible ? QAList.length : limit) : QAList.length;

  const handleToggle = (index: number) => {
    if (index === toggledIndex) {
      setToggledIndex(-1);
    } else {
      setToggledIndex(index);
    }
  };

  const toggleItemsVisibility = (boolean: boolean) => {
    setAllItemsVisible(boolean);
  };

  return (
    <div className={cx(className, 'overflow-hidden layout-column bg-neutral-100')}>
      {header && (
        <Fragment>
          <div className="bg-neutral-100 layout-row p-16 px-24 layout-align-start-center">
            {!!header.icon && <Fragment>{header.icon}</Fragment>}
            <p className="medium">{header.title}</p>
          </div>
          <Divider />
        </Fragment>
      )}
      {QAList &&
        isQAList(QAList) &&
        QAList.slice(0, updatedLimit).map((item: QuestionAndAnswer, index) => (
          <Fragment key={item.id || index}>
            <div className="layout-column layout-align-start-stretch">
              <Button
                iconRight={
                  index === toggledIndex ? (
                    <ArrowDropUpRounded style={{ fontSize: 24 }} />
                  ) : (
                    <ArrowDropDownRounded style={{ fontSize: 24 }} />
                  )
                }
                className="text-left flex-100 lh-3 p-20 bold bg-secondary-100"
                buttonVariant="default"
                truncate={false}
                buttonStyle="secondary"
                onClick={() => handleToggle(index)}
                label={
                  html ? (
                    <h3 className="p" dangerouslySetInnerHTML={{ __html: item.question as string }} />
                  ) : (
                    item.question
                  )
                }
                id={`qa-expand-toggle-btn-${index}`}
              />
              <Divider className={cx(index + 1 === QAList.length && index != toggledIndex && 'hide')} />
              {html ? (
                <div
                  className={cx(index === toggledIndex ? 'show' : 'hide', 'lh-3 p-20 bg-secondary-100')}
                  dangerouslySetInnerHTML={{ __html: item.answer as string }}
                />
              ) : (
                <div className={cx(index === toggledIndex ? 'show' : 'hide', 'lh-3 p-20 bg-secondary-100')}>
                  {item.answer}
                </div>
              )}
              <Divider className={cx(index + 1 != QAList.length && index === toggledIndex ? 'show' : 'hide')} />
            </div>
          </Fragment>
        ))}
      {limit && QAList.length > limit && (
        <Fragment>
          {QAList.length === updatedLimit && <Divider />}
          <ToggleButton handleToggled={() => toggleItemsVisibility(!allItemsVisible)} toggled={allItemsVisible} />
        </Fragment>
      )}
    </div>
  );
};
