import styled from '@emotion/styled';
import { Rating } from '@sortlist-frontend/design-system';

export const Logo = styled.img`
  width: 7rem;
  height: 1.75rem;
`;

export const GreenStars = styled(Rating)`
  svg {
    color: white;
    background-color: #00b87b;
    &:not(:last-child) {
      margin-right: 0.25rem;
    }
  }
`;
