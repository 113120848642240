export const formatMoney = (amount?: number, currency?: string): string => {
  if (amount === undefined) return '';
  return amount.toLocaleString(undefined, {
    style: 'currency',
    currency: currency || 'EUR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formatPrice = (price?: number) => {
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  }).format(price ?? 0);
};

const CURRENCY_SYMBOLS = new Map([
  ['EUR', '€'],
  ['GBP', '£'],
  ['USD', '$'],
  ['CAD', '$'],
  ['CHF', 'Fr.'],
]);

export const getCurrencySymbol = (currency: string | null | undefined): string => {
  return currency && CURRENCY_SYMBOLS.has(currency) ? (CURRENCY_SYMBOLS.get(currency) as string) : '€';
};

export const formatCurrency = ({
  value = 0,
  locale = 'en',
  currency = 'EUR',
  minimumFractionDigits = 0,
  maximumDecimalDigits = 2,
  rounded = false,
  ...options
}: {
  value: number | string;
  locale?: string;
  currency?: string;
  minimumFractionDigits?: number;
  maximumDecimalDigits?: number;
  rounded?: boolean;
} & Intl.NumberFormatOptions) => {
  const numericValue = Number(value);

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits: rounded ? 0 : maximumDecimalDigits,
    ...options,
  }).format(numericValue);
};

export function formatBigMoney({
  value = 0,
  currency = 'EUR',
  locale = 'en',
}: {
  value: number;
  currency?: string;
  locale?: string;
}) {
  const currencySymbol = getCurrencySymbol(currency);

  // Style:'currency' from NumberFormat is not used because it does not support compact notation. We use getCurrencySymbol function to get the currency symbol instead.

  return (
    currencySymbol +
    new Intl.NumberFormat(locale, {
      notation: value <= 100000 ? 'standard' : 'compact',
      compactDisplay: 'long',
    }).format(value)
  );
}
