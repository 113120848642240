import { TFunction } from '@sortlist-frontend/translation';
import { BreadcrumbJsonLd } from 'next-seo';
import { Fragment } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { LinkDetails } from '_features/longtail/utils';
import { Page } from '_types/public-api';

import { getAggregateRating } from './json-ld/aggregate-rating';
import { getBreadcrumbList } from './json-ld/breadcrumb-list';

type Props = {
  data: Page;
  breadcrumbs: LinkDetails[] | undefined;
  otherLinks: LinkDetails[] | undefined;
  homePageUrl: string;
  useAppDirectory?: boolean;
  t: TFunction;
};

export const JsonLd: React.FC<Props> = (props) => {
  const {
    data: {
      data: {
        attributes: {
          metadata: { schema_markup },
        },
      },
    },
    breadcrumbs,
    homePageUrl,
    t,
    useAppDirectory = false,
  } = props;
  const schemaMarkup = schema_markup ?? [];

  const ORG_SCHEMA = `{
    "@context":"https://schema.org",
    "@graph":[
      ${getAggregateRating('0.9', 430)}
      ${schemaMarkup.length > 0 ? ',' : ''}
      ${schemaMarkup.map((markup) => JSON.stringify(markup)).join(',')}
    ]
  }`;

  return (
    <Fragment>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ORG_SCHEMA }} />
      <BreadcrumbJsonLd
        itemListElements={getBreadcrumbList(breadcrumbs ?? [], homePageUrl, t)}
        useAppDir={useAppDirectory}
      />
    </Fragment>
  );
};
