import { cx } from '@emotion/css';

import { TrustpilotWidget } from '_components/common/TrustpilotWidget';
import { Reviews } from '_core/repos/public-api.repo';

type TrustpilotProps = {
  reviews: Reviews | undefined;
  className?: string;
};

// @TODO -> Trustpilot widget is white theme in the design, we need a dark logo to use in the widget
export const Trustpilot: React.FC<TrustpilotProps> = (props) => {
  const { reviews, className } = props;
  return (
    <div className={cx(className, 'pb-20 flex layout-column layout-align-center-center width-100')}>
      <TrustpilotWidget
        className="bg-primary-700 width-xs-100"
        reviewsCount={reviews?.reviewsCount}
        reviewsAverage={reviews?.reviewsAverage}
      />
    </div>
  );
};
