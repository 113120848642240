import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

export const AgenciesListWrapper = styled.div`
  &:last-child {
    margin-left: 1rem;
    @media only screen and (max-width: ${BREAKPOINTS.sm}) {
      margin-left: 0;
    }
  }
`;

export const Anchor = styled.a`
  :hover {
    color: ${COLORS.PRIMARY_500};
  }
`;
