import { CloseRounded, StarTwoTone } from '@mui/icons-material';
import { Tag } from '@sortlist-frontend/design-system';

type FilterTagProps = {
  id: string;
  label: string;
  filterKey: string;
  isSelected?: boolean;
  onToggle?: (id: string) => void;
  showCloseButton?: boolean;
};

export const FilterTag = ({
  id,
  label,
  filterKey,
  isSelected = false,
  onToggle,
  showCloseButton = false,
}: FilterTagProps) => {
  return (
    <button
      type="button"
      aria-label={showCloseButton ? `Remove ${label} filter` : label}
      onClick={() => onToggle?.(id)}
      className="layout-row layout-align-center-center"
      style={{ backgroundColor: 'transparent', border: 'none', padding: 0 }}>
      <Tag
        label={label}
        size="xs"
        color={isSelected ? 'primary' : 'secondary'}
        type="rounded"
        variant="light"
        iconLeft={filterKey === 'ratings' ? <StarTwoTone className="p text-secondary-900" /> : undefined}
        iconRight={
          showCloseButton ? (
            <div className="layout-row layout-align-center-center" style={{ width: 14 }}>
              <CloseRounded className="text-primary small" />
            </div>
          ) : undefined
        }
      />
    </button>
  );
};
