import sanitizeHtml from 'sanitize-html';

export const getValidHTML = (htmlString?: string) => {
  // Sometimes the html from the server is broken and it breaks the whole app html => hydration issues
  // e.g. sortlist.es/s/community-management/espana-es , paragrahs[0].description has a '<b>' that is not closing
  if (htmlString == null) return '';

  return sanitizeHtml(htmlString, {
    allowedAttributes: false, // allow all
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'svg', 'path']),
  });
};
