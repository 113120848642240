import Image from 'next/image';

import { CompanyType } from '_data/index';

type Props = {
  companies?: CompanyType[];
};

export const CompanyLogos = (props: Props) => {
  const { companies } = props;

  return (
    <div className="logo-wrapper mt-64 mt-gt-sm-0 layout-row layout-align-center-center layout-wrap">
      {companies?.map((company: CompanyType) => (
        <div
          className="layout-row layout-align-center-center relative p-16"
          key={company.id}
          style={{ maxWidth: 112, maxHeight: 80, minWidth: 112, minHeight: 80 }}>
          <Image
            loading="eager"
            className="logo company-logo"
            alt={company.name}
            src={company.logoSrc as string}
            width={80}
            height={32}
            sizes="100vw"
            key={company.id}
          />
        </div>
      ))}
    </div>
  );
};
