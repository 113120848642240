import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { Agencies, AnchorType, Links, Page, TopicContent } from '_types/public-api';

export type LinkDetails = {
  metadata: {
    hreflang: string;
    title_tag: string;
    metadescription: string;
  } | null;
  anchor: string;
  position: number;
  url: string;
};

export const filteredLinks = (links: Links | undefined, type: string): LinkDetails[] | undefined => {
  const filteredLinks = links?.data
    .filter((data) => data.attributes.category.includes(type))
    .sort((a, b) => {
      if (a.attributes.position < b.attributes.position) return -1;
      else return 0;
    });

  return filteredLinks?.map((link) => {
    const findRelation = links?.included.find((page) => page.id === link.relationships.to_page.data.id);

    return {
      metadata: link.attributes.metadata,
      anchor: link.attributes.anchor,
      position: link.attributes.position,
      url: getLocalizedUrl({ ...findRelation?.attributes, link: findRelation?.attributes.path } as AnchorType),
    };
  });
};

export const getIncludedAgenciesSlugs = (ssrAgencies: Agencies | undefined) => {
  return (
    ssrAgencies?.included &&
    ssrAgencies.included.map((obj) => {
      if (obj.attributes.team_size === null) return { ...obj, attributes: { ...obj.attributes, team_size: 0 } };
      return obj?.attributes?.slug || 0;
    })
  );
};

// Modifiers are created and managed by EPO
// Use this value to override modifiers and alway display default values
export const SHOW_MODIFIERS = true;

export const getServiceTopicId = (page: Page) => {
  return (
    page?.included.find(
      (item) => item.type === 'topic' && ['skill', 'expertise'].includes(item.attributes.external_type),
    ) as TopicContent | undefined
  )?.attributes?.graphql_id;
};
