import { CollectionsTwoTone, PeopleTwoTone, StarTwoTone, WorkspacePremiumTwoTone } from '@mui/icons-material';
import { Monitor } from '@sortlist-frontend/mlm';
import { TFunction } from '@sortlist-frontend/translation/ssr';

export type SortByAgenciesOption = {
  value: SortBy;
  label: string;
  description: string;
  icon?: React.ReactNode;
};

export const DEFAULT_SORT_BY = 'position';

const sortIconClassName = 'text-secondary-500 p';

export const sortOptions = (t: TFunction, filtersActive?: boolean): SortByAgenciesOption[] => {
  return [
    {
      value: DEFAULT_SORT_BY,
      label: Boolean(filtersActive) ? t('longtail:sortBy.relevancyLabel') : t('longtail:sortBy.recommendedLabel'),
      description: t('longtail:sortBy.byRecommended'),
      icon: <WorkspacePremiumTwoTone className={sortIconClassName} />,
    },
    {
      value: 'agencies_reviews_score',
      label: t('longtail:sortBy.ratingLabel'),
      description: t('longtail:sortBy.byRating'),
      icon: <StarTwoTone className={sortIconClassName} />,
    },
    {
      value: 'works_count',
      label: t('longtail:sortBy.numberOfWorksLabel'),
      description: t('longtail:sortBy.byNumberOfWorks'),
      icon: <CollectionsTwoTone className={sortIconClassName} />,
    },
    {
      value: 'agencies_size',
      label: t('longtail:sortBy.teamSizeLabel'),
      description: t('longtail:sortBy.byTeamSize'),
      icon: <PeopleTwoTone className={sortIconClassName} />,
    },
  ];
};

type SortBy = 'position' | 'agencies_reviews_score' | 'works_count' | 'agencies_size';
type SortByJsonApi = 'position' | 'reviews_score' | 'works_count' | 'team_size';

const JSON_API_SORT_BY: Record<SortByJsonApi, string> = {
  position: 'position',
  reviews_score: 'reviews_score',
  works_count: 'works_count',
  team_size: 'team_size',
};

const GRAPHQL_SORT_BY: Record<SortBy, SortBy> = {
  position: 'position',
  agencies_reviews_score: 'agencies_reviews_score',
  works_count: 'works_count',
  agencies_size: 'agencies_size',
};

export const getGraphqlSortBy = (sortBy: string) => {
  if (sortBy === 'team_size') {
    return 'agencies_size';
  }
  if (sortBy === 'reviews_score') {
    return 'agencies_reviews_score';
  }

  if (sortBy in GRAPHQL_SORT_BY) {
    return GRAPHQL_SORT_BY[sortBy as SortBy];
  } else {
    Monitor.captureException(new Error(`SortBy ${sortBy} not found in GRAPHQL_SORT_BY`));
  }
  return sortBy;
};

export const getJsonApiSortBy = (sortBy: string) => {
  if (sortBy === 'agencies_size') {
    return 'team_size';
  }
  if (sortBy === 'agencies_reviews_score') {
    return 'reviews_score';
  }

  if (sortBy in JSON_API_SORT_BY) {
    return JSON_API_SORT_BY[sortBy as SortByJsonApi];
  } else {
    Monitor.captureException(new Error(`SortBy ${sortBy} not found in JSON_API_SORT_BY`));
  }
  return sortBy;
};
