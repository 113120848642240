import { FC, ReactNode, useEffect, useState } from 'react';

export const NoSsr: FC<{ children?: ReactNode }> = ({ children }) => {
  const [isMounted, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  return <>{isMounted ? children : null}</>;
};
