import { Monitor } from '@sortlist-frontend/mlm';
import { useQuery } from '@tanstack/react-query';

import { api, PUBLIC_API_PROXY_URL } from '_core/api/api-nextjs-proxy';
import { acceptLanguageHeader } from '_core/api/api.utils';
import { Domain, SearchPage } from '_types/public-api';

import { DEFAULT_LOCAL_ORIGIN_DOMAIN } from '../public-api.repo';

const pagesQuery = `
  query searchPages($host: String!, $associatedTopics: [ID!], $locale: ID!) {
    searchPages(
      first: 1,
      filter: {
        host: $host,
        locale: $locale,
        topics: $associatedTopics
      }
    ) {
      nodes {
        url
        id
      }
    }
  }
`;

export type SearchPageArgs = {
  domain?: Domain;
  topics: string[];
  locale: string;
  baseURL: string;
  enabled: boolean;
};

export type TopicType = 'service' | 'location';

export type FilterParams = {
  host: string;
  associatedTopics: string[];
  locale: string;
};

const getResultUrl = (url: string, baseURL: string) => {
  let replaceUrl = null;

  // easier to work in development/sandbox
  if (['localhost', 'pr-'].some((host) => baseURL.includes(host))) {
    replaceUrl = baseURL;
  }
  if (replaceUrl != null) {
    return url.replace('https://', '').replace(DEFAULT_LOCAL_ORIGIN_DOMAIN, replaceUrl);
  }
  return url;
};

export const pagesRepo = {
  getPages: async (params: FilterParams, locale: string, baseURL: string): Promise<SearchPage> => {
    const returnObject = await api.post(
      PUBLIC_API_PROXY_URL,
      { query: pagesQuery, variables: { ...params } },
      { baseURL, headers: { 'Accept-Language': acceptLanguageHeader(locale) } },
    );

    const response = returnObject?.data;

    if (response?.errors != null) {
      Monitor.captureException(
        new Error(`Error for searchTopics: ${JSON.stringify(returnObject?.data?.errors ?? {})}`),
      );
    }

    const result = response?.data?.searchPages?.nodes?.[0];

    return {
      id: result?.id,
      url: result?.url != null ? getResultUrl(result?.url, baseURL) : '',
    };
  },
};

export const useSearchPages = ({ domain, topics, locale, baseURL, enabled = true }: SearchPageArgs) => {
  return useQuery({
    queryKey: ['topics', domain?.host ?? 'nodomain', topics.join(','), locale],
    queryFn: () => {
      if (domain == null) {
        return Promise.resolve(null);
      }
      const localeId = domain.availableLocales.find((domainLocale) => domainLocale.code === locale)?.id;
      if (localeId == null) {
        return Promise.resolve(null);
      }

      const params: FilterParams = {
        host: domain.host,
        associatedTopics: [localeId, ...topics],
        locale: localeId,
      };

      return pagesRepo.getPages(params, locale, baseURL);
    },
    enabled: enabled && Boolean(domain != null),
  });
};
