'use client';

import { Button } from '@sortlist-frontend/design-system/ssr';

import { OPEN_BRIEFING, useBriefingMessage } from '_components/Briefing/versions/NormalBriefing/BriefingDialog';
import { NavigationData } from '_components/layout/Layout';

type Props = {
  label: string;
  navigationData: NavigationData;
};

export const BriefingHeroCta = (props: Props) => {
  const { label, navigationData } = props;
  const { sendMessage } = useBriefingMessage();

  return (
    <Button
      size="lg"
      buttonStyle="secondary"
      buttonVariant="raised"
      className="mb-8 mt-gt-xs-20 mx-6 px-gt-sm-48"
      data-testid="briefing-hero-btn"
      label={label}
      onClick={() => {
        sendMessage(OPEN_BRIEFING, { cta: 'hero', expertiseId: navigationData.expertiseId });
      }}
    />
  );
};
